import React, {useContext, useEffect, useState} from 'react';
import {MediaContext} from "../MediaContext";
import kildebilder from './nyhetskildebilder.json'
import nyhetskilder from './nyhetskilder.json'



const ProfileComponent = ({ id, medie, alt, dataUserId }) => {
    const [isChecked, setIsChecked] = useState(true);

    const { uncheckedMedia, addUncheckedMedia, removeUncheckedMedia, profiles, setProfiles, setMediaOrder } = useContext(MediaContext);


    const handleProfileClick = () => {
      setIsChecked(!isChecked);

      if (uncheckedMedia.includes(medie)) {
        removeUncheckedMedia(medie);
      } else {
        addUncheckedMedia(medie);
      }
    };

    const profileClassName = isChecked ? 'profile checked' : 'profile unchecked';


    const [isDragging, setIsDragging] = useState(false);


    // Denne repeater jævlig ofte og mye burde effektiviseres
    useEffect(() => {

        const indexUserMedias = () => {
            let order = [];
            profiles.map((entry, index) => {
                //console.log(entry, index)
                //console.log(Object.keys(nyhetskilder).indexOf(entry))

                order.push(Object.keys(nyhetskilder).indexOf(entry))
            })
            //console.log('order: ', order)
        };
        indexUserMedias()

        }, [profiles]);


    function swapItems(index1, index2) {
      if (index1 < 0 || index1 >= profiles.length || index2 < 0 || index2 >= profiles.length) {
          throw new Error('Invalid indices provided');
      }
      // Create a copy of the list
      const newList = [...profiles];

      // Swap the elements
      const temp = newList[index1];
      newList[index1] = newList[index2];
      newList[index2] = temp;
      //console.log(newList)


      return newList;
    }

    function handleDragStart(event, id) {
        setIsDragging(true);
        event.dataTransfer.setData("text/plain", id);
    }




    function handleDrop(event, id) {
        event.preventDefault(); // Prevent default behavior (prevents the browser default action for the dataTransfer operation)
        const draggedItemId = event.dataTransfer.getData("text/plain"); // Get the ID from the dataTransfer object
        const swappedlist = swapItems(draggedItemId, id)
        setProfiles(swappedlist)
        //console.log(draggedItemId)
    }

    function handleDragOver(event) {
        event.preventDefault(); // Necessary. Allows us to drop.
    }

    function handleDragEnter(event) {
        event.preventDefault();
    }





    return (
    <div className={profileClassName}
         id={id}
         data-user-id={dataUserId}
         onClick={handleProfileClick}
         draggable='true'
         onDragStart={(e) => handleDragStart(e, dataUserId)}  // Pass the ID as a parameter
         onDrop={(e) => handleDrop(e, dataUserId)}
         onDragOver={handleDragOver}
         onDragEnter={handleDragEnter}

        >
        <img src={kildebilder[medie]} alt={alt} />
        <input type='checkbox' className='profile-checkbox' checked={!uncheckedMedia.includes(medie)} />
        <div className='status-circle'></div>
    </div>
    );
};

export default ProfileComponent