import React, { useState } from 'react';
import kildebilder from './nyhetskildebilder.json'
import nyhetssider from './nyhetskilder.json'


const IndividualPost = ({ entry, bool }) => {
  // sjekke om det er individual eller group
  let secondImage = null;
  let diffclassname = null;

  if (bool === 'indie') {
    secondImage = <img src={kildebilder[entry.medie]} className='single-slide-img' alt='single-slide-profile' />
    diffclassname = 'post'
  } else {
      let secondImage = null;
      diffclassname = 'carpost'
  }
  // console.log(bool)
  // sjekk om teksten er for lang for posten, hvis den er det gjør teksten mindre
  const maxCharacters = 50; // Set your desired maximum number of characters
  const normalFontSize = '45px'; // Set the normal font size
  const reducedFontSize = '30px'; // Set the reduced font size for longer text

  const isTextTooLong = entry && entry.tekst && entry.tekst.length > maxCharacters;


  const [isButtonHovered, setButtonHovered] = useState(false);

  const textStyle = {
    fontSize: isTextTooLong && diffclassname === 'carpost' ? reducedFontSize : normalFontSize
    // Add other styles as needed




  };

  return (
    <div className={diffclassname} style={{ cursor: 'pointer', position: 'relative', maxHeight: '700px' }}>
      <img src={entry.photo_url} className='post-img' alt='profile' onClick={() => window.open(entry.lenke, '_blank')} />
      <p className='post-text' style={textStyle} onClick={() => window.open(entry.lenke, '_blank')} >{entry.tekst}</p>
      <img src={kildebilder[entry.medie]} className='profile-img' alt='single-slide-profile' onClick={() => window.open(nyhetssider[entry.medie])} style={{ width: '25%' }} />
      <p className='dato-text' onClick={() => window.open(entry.lenke, '_blank')} >{entry.dato}</p>
      <img className='hashtag'
           src={kildebilder['hashtag']}
           alt='tags'
           onClick={() => setButtonHovered(true)}
           onMouseLeave={() => setButtonHovered(false)}
           style={{ width: '25%' }}/>
      {secondImage}
      {isButtonHovered && (
        <div className='hoover'>
          <p>{entry.tags}</p>
        </div>
      )}


    </div>
  );
};

export default IndividualPost