import React from "react";
import './sidebar-styles.css'
import kildebilder from './nyhetskildebilder.json'
import nyhetskilder from './nyhetskilder.json'

const Sidebar = () => {
    const metadata = window.localStorage.getItem('metadata').split('/')
    const inits = metadata[0]
    const medier = metadata[1].split(',')
    const tagger = metadata[2].split(',')
    console.log(medier)


    return (


        <div className="containeren">

              {/* Financial Summary */}
              <div className="kategori">
                <h2 className="text-xl font-semibold mb-4">Dine Medier</h2>
                <div style={{ display: "grid", gridTemplateColumns: 'repeat(3, 1fr)', gap: '25px'}}>
                  {medier.map((element, index) => (
                    <p key={index}>
                        <img src={kildebilder[Object.keys(nyhetskilder)[element]]} style={{ width: '100px', height: "auto", borderRadius: '50%' }}/>
                    </p>
                  ))}
                </div>
              </div>

              {/* Recent Transactions */}
              <div className='kategori'>
                <h2 className="text-xl font-semibold mb-4">Tags</h2>
                <div>
                  {tagger.map((element, index) => (
                    <p key={index}>{element}</p>
                  ))}
                </div>
              </div>
            </div>
          );
}

export default Sidebar