import {useState} from 'react';
import kildebilder from './components/nyhetskildebilder.json'
import { auth } from './config/firebase.js'
import { createUserWithEmailAndPassword, updateProfile, getAuth } from 'firebase/auth'
import { Link } from 'react-router-dom';

const FireSignUp = () => {
    const [email, setEmail] = useState("")
    const [pwd, setPwd] = useState("")
    const [fornavn, setFornavn] = useState("")
    const [etternavn, setEtternavn] = useState("")
    const signIn = async (e) => {
        // stopp siden fra å refreshe
        e.preventDefault()
        console.log('signed in: ', auth?.currentUser?.displayName)
        try {
            const userCredential = await createUserWithEmailAndPassword(auth, email, pwd);
            if (userCredential && userCredential.user) {
                await updateProfile(userCredential.user, {
                    displayName: fornavn + ' ' + etternavn,
                    preferences: JSON.stringify('///')
                    // Add other user details as needed
                });
                const prefs = getAuth().user.preferences
                console.log('User created and profile updated successfully!');
                console.log('preferences: ', prefs);
                window.localStorage.setItem('metadata', prefs)
                console.log('lol')
            }
        } catch (error) {
            console.error('Error creating user or updating profile:', error);
        }
    }
    // console.log(auth?.currentUser?.displayName)

  return (
    <div className='tihihi'>

      <form>
        <img src={kildebilder['logo']} style={{ display: 'block', marginRight: 'auto', marginLeft: 'auto', width: '50%', borderRadius: '50%', alignContent: 'center', padding: '20px' }}/>


          <input
          type="text"
          name="fornavn"
          placeholder="Fornavn"
          autoFocus='true'
          onChange={(e) => setFornavn(e.target.value)}
          className="tihihi-input"
        />

          <input
          type="text"
          name="etternavn"
          placeholder="Etternavn"
          onChange={(e) => setEtternavn(e.target.value)}
          className="tihihi-input"
        />
        <input
          type="text"
          name="email"
          placeholder="Email"
          onChange={(e) => setEmail(e.target.value)}
          className="tihihi-input"
        />
        <input
          type="password"
          name="pwd"
          placeholder="Password"
          onChange={(e) => setPwd(e.target.value)}
          className="tihihi-input"
        />
        <button type="submit" className="tihihi-button" onClick={(e) => signIn(e)}><Link to='/'>Sign up</Link></button>
          <div className="form-link">
            <span>Already have an account? <a><Link to='/login'> Login</Link></a></span>
          </div>
      </form>

    </div>
  );
};

export default FireSignUp;
