import {MediaProvider} from "./MediaContext";
import Header from "./components/header";
import Leftbox from "./components/leftbox";
import Rightbox from "./components/rightbox";
import Overskrift from "./components/overskriftkategori";
import Feed from "./components/feed";
import React from "react";

const Politikk = () => {
    return (
        <div className="app">
            <MediaProvider>
                <Header />
                <Overskrift />

            </MediaProvider>
        </div>
    );
};

export default Politikk;