import {React, useEffect, useState} from "react";
import jsonData from './components/credentials.json'
import { getAuth, signOut, signInWithEmailAndPassword, onAuthStateChanged } from 'firebase/auth';


import {auth} from "./config/firebase";
import {get} from "axios";


const Test = () => {

  useEffect(() => {
    // Update the title when the component mounts
    document.title = 'Test'; // Set your desired tab title here
  }, []); // Empty dependency array ensures this effect runs once when the component mounts


    const auth = getAuth();
    const user = auth.currentUser;
    const [navn, setNavn] = useState('')

    useEffect(() => {
      const unsubscribe = onAuthStateChanged(auth, (user) => {
        if (user) {
          // User is signed in
          setNavn(auth?.currentUser?.displayName)
          // You can set user state, navigate to the dashboard or home page, etc.
        } else {
          // User is signed out
          setNavn('ikke innlogget')
          // Navigate to the login page or update the user interface accordingly
        }
      });

      // Cleanup subscription on component unmount
      return () => unsubscribe();
    }, [user]);

    const leggo = () => {

        if (user) {
            const displayName = auth?.currentUser?.displayName;

            if (displayName) {
                console.log(`User's Display Name: ${displayName}`);
                setNavn(displayName)
            } else {
                console.log('User does not have a display name set.');
            }
        } else {
            console.log('No user is currently signed in.');
        }
    }
    const email = 'haakon.olssoen@hotmail.com'
    const password = 'Haakon1412'
    const loggInn = (e) => {
      signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          // Signed in
          const user = userCredential.user;
          console.log('User logged in successfully:', user);
          // Navigate to the dashboard or home page or update the user interface
        })
        .catch((error) => {
          // Handle Errors here
          const errorCode = error.code;
          const errorMessage = error.message;
          console.error('Error signing in:', errorMessage);
          // Display error message to the user or handle error scenarios
        });
    }
    const loggUt = (e) => {
      signOut(getAuth())
        .then(() => {
          // Sign-out successful.
          console.log('User signed out successfully');
        })
        .catch((error) => {
          // An error occurred during sign-out.
          console.error('Error signing out:', error);
        });
    }

    return (
        <div style={{ position: "absolute", left: '50%', translate: 'transformX(-50%)', display: "flex", flexDirection: "column" }}>
            <>
                <p style={{ padding: '50px'}}>Credentials</p>

                <button onClick={leggo}>get navn</button>
                <div>{auth?.currentUser?.email}</div>
                <div>navn:</div>
                <div>{navn}</div>

            </>

            <>
                <p style={{ padding: '50px'}}>Login</p>
                <button onClick={loggInn}>Logg inn</button>
                <button onClick={loggUt}>Logg ut</button>
            </>
        </div>

    )
}
export default Test