import React, { useContext, useEffect, useState } from 'react';
import IndividualPost from "./indies";
import Karrusel from "./karusell";

import {MediaContext} from "../MediaContext";


const Feed = () => {
    const [posts, setPosts] = useState([]);

    const {uncheckedMedia} = useContext(MediaContext);
    const {LaFilteredTags} = useContext(MediaContext);
    const {dataSource} = useContext(MediaContext);
    const checkIfUnchecked = (ValueToCheck) => {
        // console.log(ValueToCheck, uncheckedMedia.includes(ValueToCheck))
        return uncheckedMedia.includes(ValueToCheck);
    };

    const checkIfLaFiltered = (TagToCheck) => {
        // Split the string into an array of tags
        const tags = String(TagToCheck).split(',').map(tag => tag.trim());


        // Check if tags array contains multiple tags (separated by commas)

            // Handle single tag (e.g., perform inclusion check with LaFilteredTags)
        const includesTag = tags.some(tag => LaFilteredTags.includes(tag));
        return includesTag;
        }


    const checkOverlaps = (group) => {
      const arrr = [];
      group.map((entry, index) => arrr.push(entry.medie));
      return arrr.every((element) => uncheckedMedia.includes(element));
    };

    const addMultiTags = (tags) => {
        return tags.split(',')
    }

    const checkTags = (group) => {
        const tags = []
        group.map((entry, index) => {
            if (entry?.tags?.length > 1) {
                addMultiTags(entry.tags).map((entry, index) => tags.push(entry))
            } else {
                tags.push(entry.tags)

            }
        });
        //console.log(tags)
        //console.log(LaFilteredTags)

        return tags.some((element => LaFilteredTags.includes(element)))
    };




    useEffect(() => {
        setPosts([])
        fetch(`https://fidia.no/data_eh/${dataSource}.json`)
          .then((response) => {
            if (!response.ok) {
              console.log('Network response was not ok', response.status, response.statusText);
              throw new Error('Network response was not ok');
            }
            return response.json();
          })
          .then((data) => {
            console.log('datasource', `/data_eh/${dataSource}.json`);
            setPosts(data);
          })
          .catch((error) => {
            console.error('Error fetching data:', error);
            setPosts([]); // Set default value or handle the error as needed
          });
      }, [dataSource]);
      





    return (
        <div className='feed'>
            {posts.map((entry, index) => {
                // console.log(`Index: ${index}, Entry Tag:`, entry.tags, 'LaFilteredTags: ', LaFilteredTags, 'bool: ', checkIfLaFiltered(entry.tags));
                if (!entry.grouped_entries) {
                    if (checkIfLaFiltered(entry.tags)) {

                        return null;
                    }
                    if (checkIfUnchecked(entry.medie)) {
                        // console.log(checkIfLaFiltered(entry.tags))

                        return null;

                    } else {
                        //console.log(`${index}`)

                        return <IndividualPost key={index} entry={entry} bool={'indie'} />;


                    }
                } else {
                    if (checkOverlaps(entry.grouped_entries)) {

                        return null;
                    }
                    if (checkTags(entry.grouped_entries)) {

                        return null;
                    } else {


                        return <Karrusel key={index} groupedEntries={entry.grouped_entries} />;
                    }
                }
            })}
        </div>

    )
}

export default Feed