// Header component
import React, {useContext, useEffect, useState} from "react";
import {MediaContext} from "../MediaContext";
import { Link, useLocation } from 'react-router-dom';
import kildebilder from './nyhetskildebilder.json'
import {getAuth, onAuthStateChanged} from "firebase/auth";




const Header = () => {
    const { initials, addInitials, loggedIn, setLoggedIn, clearLaFilteredTags, addDataSource, addOverskrift, defaultProfiles } = useContext(MediaContext);
    let inits

    try {
        if (window.localStorage.getItem('loggedIn') && window.localStorage.getItem('metadata')) {
            inits = window.localStorage.getItem('metadata').split('/')[0]
            setLoggedIn(true)


        } else {
            inits = 'Logg inn'
            setLoggedIn(false)
        }
    } catch {
        setLoggedIn(false)
    }


    const auth = getAuth();
    const user = auth.currentUser;

    useEffect(() => {
      const unsubscribe = onAuthStateChanged(auth, (user) => {
        if (user) {
          // User is signed in
          const navn = auth?.currentUser?.displayName.split(' ')
          addInitials(navn[0][0] + navn[navn.length-1][0])
          setLoggedIn(true)
          // You can set user state, navigate to the dashboard or home page, etc.
        } else {
          // User is signed out
          setLoggedIn(false)
          // Navigate to the login page or update the user interface accordingly
        }
      });

      // Cleanup subscription on component unmount
      return () => unsubscribe();
    }, [user]);



    const [dropdownOpen, setDropdownOpen] = useState(false);

    const [accountDropdownOpen, setAccountDropdownOpen] = useState(false);


    const options = ['Kultur', 'Musikk', 'Film', 'Rampelys', 'ting', 'tang', 'og', 'såånt', 'da'];

    const location = useLocation();

    // Extract the pathname from the location object
    const currentPath = location.pathname;


    //console.log('logged in: ',loggedIn)
    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };

    const toggleDropdownOff = () => {
        setDropdownOpen(false)
    }

    const toggleAccountDropdown = () => {
        setAccountDropdownOpen(!dropdownOpen);
    };

    const toggleAccountDropdownOff = () => {
        setAccountDropdownOpen(false)
    }

    const logOut = () => {
        setLoggedIn(false)
        window.localStorage.clear()
        clearLaFilteredTags()
        defaultProfiles()
    }

    const fidiaMouseOver = () => {
        console.log(auth?.currentUser?.preferences)
    }


    return (
        <div className="menu-bar"  onMouseLeave={() => {toggleDropdownOff();toggleAccountDropdownOff();}}>
            <a href='/' className="clickable-image">
                <img src={kildebilder['nameandlogo']} className="menu-logo" onMouseOver={fidiaMouseOver}/>
            </a>
            <ul>

                <li className={currentPath === '/' ? 'menu-item active' : 'menu-item'}><a href="/">Forsider</a></li>
                <li className={currentPath === '/sport' ? 'menu-item active' : 'menu-item'}><Link to="/sport">Sport</Link></li>
                <li className={currentPath === '/politikk' ? 'menu-item active' : 'menu-item'}><Link to="/politikk">Politikk</Link></li>
                <li className={currentPath === '/business' ? 'menu-item active' : 'menu-item'}><Link to="/business">Business</Link></li>
                <li className="menu-item"><a className={dropdownOpen === true ? 'menu-item diverse' : 'menu-item'} onMouseOver={toggleDropdown}>Diverse</a></li>
                {/* Dropdown list */}
                {dropdownOpen && (
                <ul className="diverse-dropdown-list">
                  {options.map((option, index) => (
                    <li key={index} className="diverse-dropdown-item" onClick={() => console.log(option)}>
                      {option}
                    </li>
                  ))}
                </ul>
                )}


            </ul>
            <div className='user-container' style={{ display: "flex", alignItems: "center",  }} >
                {loggedIn ? (
                    <div className='account' onMouseOver={toggleAccountDropdown} >
                        <div className='initials'>{initials}</div>

                    </div>

                ) : (
                    <Link to="/login">
                        <div className="log-sign-container">
                            {/*<button className='log-sign'>Login</button>*/}
                            <img src={kildebilder['no-profile']} alt='log in'
                                 style={{ width: '50px',height: 'auto', borderRadius: '50%' }} />
                        </div>
                    </Link>
                )}
                {accountDropdownOpen && (
                    <div className={accountDropdownOpen === true ? 'account-dropdown open' : 'account-dropdown'}>
                        <ul className='account-dropdown-list'>
                            <li classname='account-dropdown-item' >
                                <Link to="/konto">Konto</Link>
                            </li>
                            <li classname='account-dropdown-item' >Darkmode</li>
                            <li classname='account-dropdown-item' onClick={logOut}>
                                <Link to="/">Logg ut</Link>
                            </li>
                        </ul>
                    </div>
                )}
            </div>


        </div>
    );
};
export default Header;