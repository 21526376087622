import React, {useContext, useState} from 'react';
import { BsChevronCompactLeft, BsChevronCompactRight } from 'react-icons/bs';
import kildebilder from "./nyhetskildebilder.json";
import IndividualPost from "./indies";
import {MediaContext} from "../MediaContext";



const Karrusel = ({ groupedEntries }) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const mediasInGrp = []

    {
        groupedEntries.map((entry, index) => {
            mediasInGrp.push(entry.medie)
        })
    }
    const prevSlide = () => {
        if (slides.length === 0) {
            // Handle case where slides array is empty
            return;
        }

        const newIndex = (currentIndex - 1 + slides.length) % slides.length;
        setCurrentIndex(newIndex);
    };

    const nextSlide = () => {
        if (slides.length === 0) {
            // Handle case where slides array is empty
            return;
        }

        const newIndex = (currentIndex + 1) % slides.length;
        setCurrentIndex(newIndex);
    };


    const {uncheckedMedia} = useContext(MediaContext);
    const [checkedMedia, setCheckedMedia] = useState([]);

    const filteredGroupedEntries = groupedEntries.filter(function (entry) {
        const isUnchecked = uncheckedMedia.includes(entry.medie);
        const isChecked = checkedMedia.includes(entry.medie);

        if (isUnchecked || isChecked) {
            return !isUnchecked; // If unchecked, exclude from filtered entries; otherwise, include it
        }

        return true; // Include entries that are not checked or unchecked by default
    });

    const maybeNewIndex = () => {
        if (currentIndex === filteredGroupedEntries.length) {
            let newIndex = currentIndex - 1;
            setCurrentIndex(newIndex);
        }
    }
    maybeNewIndex()

    const slides = filteredGroupedEntries;


    const slideIndex = (slides, handleIndexClick) => {
        let slidePhotos = [];

        slides.map((entry, index) => {
            slidePhotos.push(
                <img
                    key={index}
                    src={kildebilder[entry.medie]}
                    className={`slide-${index}`}
                    alt={`Slide ${index}`}
                    style={{
                        width: '25px',
                        height: '20px',
                        bottom: '600px',
                        borderRadius: '70%',
                        margin: '5px',
                        cursor: 'pointer',
                        transform: index === currentIndex ? 'scale(1.2)' : 'scale(1)',
                        transition: 'transform 0.3s',
                        userSelect: 'none'
                    }}
                    onClick={() => handleIndexClick(index)} // Click handler for switching the carousel
                />
            );
            return null;
        });

        return slidePhotos;
    };


    return (
        <div className='carousel-container'>
            <IndividualPost entry={slides[currentIndex]} bool={'karrusell'}/>
            {slides.length > 1 && (
                <>
                    <BsChevronCompactLeft
                        onClick={prevSlide}
                        size={70}
                        color={'white'}
                        style={{
                            position: 'absolute',
                            cursor: 'pointer',
                            bottom: '0',
                            left: `5px`,
                            transform: 'translateY(-50%)'
                        }}
                    />
                    <BsChevronCompactRight
                        onClick={nextSlide}
                        size={70}
                        color={'white'}
                        style={{
                            position: 'absolute',
                            cursor: 'pointer',
                            bottom: '0',
                            right: `5px`,
                            transform: 'translateY(-50%)'
                        }}
                    />
                </>
            )}

            <div className='slideIndexDiv'
                 style={{
                     display: 'flex',
                     position: 'absolute',
                     overflow: 'hidden',
                     bottom: '30px',
                     left: '50%',
                     transform: 'translateX(-50%)'
                 }}>
                {slideIndex(slides, setCurrentIndex)}
            </div>
        </div>
    );
}

export default Karrusel;