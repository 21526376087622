import React, {useContext, useState, useEffect} from 'react';
import {MediaContext} from "../MediaContext";
import kildebilder from "./nyhetskildebilder.json";
import creds from './credentials.json'



const Livealgo = () => {
    const {LaFilteredTags, addLaFilteredTags, createLaFilteredTags, removeLaFilteredTags} = useContext(MediaContext);


      useEffect(() => {
        // Check if user is logged in
        if (window.localStorage.getItem('loggedIn') && window.localStorage.getItem('metadata')) {
          // Retrieve and process metadata from local storage
          const metadata = window.localStorage.getItem('metadata');
          console.log(metadata)
          if (metadata) {
            const metasplit = metadata.split('/')
            const tags = metasplit[1].split(',');
            console.log('meta tags: ', tags)
            //console.log(tags)
            // Perform actions with tags (e.g., addLaFilteredTags(tags))
            if (tags.length > 1) {
                createLaFilteredTags(tags)
            }

            //console.log(tags); // Log tags to the console or perform other actions as needed
          }
        }

      }, []); // Empty dependency array ensures the code runs once after component mounts


      useEffect(() => {
        try {
            if (window.localStorage.getItem('metadata')) {
                // Convert LaFilteredTags array to a string using JSON.stringify and store it in local storage
                const metadata = window.localStorage.getItem('metadata')
                const newMetaTags = LaFilteredTags
                const metaParts = metadata.split('/')
                metaParts[1] = LaFilteredTags
                const newMetaData = metaParts.join('/')
                console.log('metadata: ', metadata)
                console.log('newmetatags: ', newMetaTags)
                console.log('metaparts: ', metaParts)
                console.log('new', newMetaData)

                window.localStorage.setItem('metadata', newMetaData)

                // Oppdater credentials fil
                const username = window.localStorage.getItem('user')
                //console.log(creds[username][1])


                //const entryToUpdate = creds.find((entry) => entry[0] === username);
                //console.log(entryToUpdate[1])
                // If the entry is found, update the metadata (second element of the array)
                //if (entryToUpdate) {
                    //entryToUpdate[1] = newMetaData;
                    //console.log(creds); // This will show the updated creds array
                //} else {
                    //console.log("Username not found.");
                //}

            }


        } catch (error) {
            console.log('live algo error: ', error)
        }



        // This will run whenever LaFilteredTags changes
      }, [LaFilteredTags]); // Dependency array: useEffect will re-run if LaFilteredTags changes



    const handleTagEntry = (entry) => {
        if (LaFilteredTags.includes(entry)) {
            removeLaFilteredTags(entry);
      } else {
            addLaFilteredTags(entry);
      }
    }

    const [isSearchBarVisible, setSearchBarVisible] = useState(false);
    const [searchValue, setSearchValue] = useState('');



    const liveAlgoSearchbar = () => {
        setSearchBarVisible(!isSearchBarVisible);
    };
    const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
  };

    const tagButton = (tag) => {
        if (LaFilteredTags.includes(tag)) {
                removeLaFilteredTags(tag)

        }
    }

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            // Pressed Enter, do something with the searchValue (e.g., trigger search)
            //console.log('Search value:', searchValue);
            handleTagEntry(searchValue)
            // You can perform additional actions or trigger a search function here
            setSearchValue('')
            // Optionally, hide the search bar after pressing Enter
            setSearchBarVisible(false);
        }
    };

    return (
        <div className='live-algo'>
            <div className='live-algo-top'>
                <div className='live-algo-headline'>Live-Filter</div>
                <div className='tag-add-button'>
                    <input type='checkbox' className='tag-entry-checkbox' id='tagCheckBox' checked=''/>
                    <img src={kildebilder['add']} alt='eheh' style={{ height: "40px", width: "40px", borderRadius: "30%", justifyItems: "center" , justifyContent: "center" }} onClick={liveAlgoSearchbar}/>
                </div>

            </div>
            {isSearchBarVisible && (
                <input
                  autoFocus
                  value={searchValue}
                  onChange={handleSearchChange}
                  onKeyPress={handleKeyPress} // Listen for key press
                  placeholder='Filtrer tags'
                  className='live-algo-searchbar'
                  type='search'
                  style={{ position: 'relative', boxSizing: 'border-box', alignSelf: 'center', height: '30px', width: '98%', fontSize: '18px' }}
                />
              )}
            <div className='tags'>
                {LaFilteredTags.map((entry, index) => (
                    <div className='tag'>
                        <p className='tag-text'>{entry}</p>
                        <button className='tag-btn' onClick={() => tagButton(entry)}>×</button>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Livealgo