import React, { useContext, useState, useEffect } from 'react';
import {MediaContext} from "../MediaContext";
import kildebilder from './nyhetskildebilder.json'
import kilder from './medie-info.json'



const AddProfileComponent = () => {
    const { profiles, setProfiles, uncheckedMedia, daOptions, setDaOptions } = useContext(MediaContext)
    const [isProfileSearchBarVisible, setProfileSearchBarVisible] = useState(false);

    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredOptions, setFilteredOptions] = useState([...daOptions])


    const toggleDropdown = () => {
        setDropdownOpen(!isDropdownOpen);
    };

    useEffect(() => {
        // console.log('search term: ', searchTerm)
        // console.log('------------------------')
        setFilteredOptions(
            daOptions.filter((option) =>
            option.toUpperCase().startsWith(searchTerm.toUpperCase()))
        )

    }, [searchTerm, daOptions]); // This will run every time searchTerm changes

    const handleInputChange = (e) => {
        setSearchTerm(e.target.value);
    };


    const popSearchBar = () => {
        setProfileSearchBarVisible(!isProfileSearchBarVisible);
        toggleDropdown()
    }


    const dropdownClick = (option) => {
        // Create a copy of the list
        const newList = [...profiles];
        const newOptions = [...daOptions]
        const toBeDeleted = newList[7]
        newOptions.push(toBeDeleted)
        const newNewOptions = newOptions.filter(item => item !== option)
        setDaOptions(newNewOptions)
        newList.splice(7, 1, option)
        setProfiles(newList)
        popSearchBar()
    }

    const dropdownDrag = (event, option) => {
        // Set the drag data if needed
        event.dataTransfer.setData('text/plain', option);

        // Create a static drag image that mimics the appearance of your dynamic image
        const dragImage = new Image();
        dragImage.src = kildebilder[option]; // Path to your static drag image


        dragImage.setAttribute('class', 'profile-img')


        // Set the static image as the drag image
        event.dataTransfer.setDragImage(dragImage, 0, 0);

    }
    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            dropdownClick(searchTerm.toUpperCase())
            setSearchTerm('')
            setProfileSearchBarVisible(false);
        }
    };

    return (
        <>
            <div className='profile checked' id='8' data-user-id='8' onClick={popSearchBar} style={{ cursor: "pointer", borderRadius: "50%" }}>
                <img src={kildebilder['add']} alt='lol' style={{ borderRadius: "30%" }} />

            </div>
            {isProfileSearchBarVisible && (
                <input
                  onChange={handleInputChange}
                  onKeyPress={handleKeyPress} // Listen for key press
                  className='profile-searchbar'
                  type='text'
                  placeholder='Søk etter medie'
                  autoFocus
                  style={{ position: 'relative',  boxSizing: 'border-box', alignSelf: 'center', height: '30px', width: '294%', left: "3%", fontSize: '18px', top: "-30%" }}
                />
              )}
        {isDropdownOpen && isProfileSearchBarVisible && (
            <div className="dropdown-content">
              {filteredOptions.map((option, index) => (
                <div key={index} onClick={() => dropdownClick(option)} draggable='false' onDragStart={(event) => dropdownDrag(event, option)} style={{ zIndex: '9999', flexGrow: "1" }}>
                  <img src={kildebilder[option]} alt='lol' style={{ position: "relative", height: "25px", width: "25px", borderRadius: "70%", marginRight: "15px", marginLeft: "10px", transform: "translateY(20%)" }}/>
                  {kilder[option][1]}
                </div>
              ))}
            </div>
          )}
        </>
    );
};

export default AddProfileComponent