import React, {useContext, useEffect, useState} from 'react';
import {MediaContext} from "../MediaContext";
import { Link, useLocation } from 'react-router-dom';


const Overskrift = () => {
    // const [isFixed, setIsFixed] = useState(false);

    const { overskrift, addOverskrift } = useContext(MediaContext);
    const location = useLocation();
    const currentPath = location.pathname;

    if (currentPath !== '/') {
        const modifiedString = currentPath.slice(1)
        const capitalizedString = modifiedString.charAt(0).toUpperCase() + modifiedString.slice(1);

        addOverskrift(capitalizedString)
    }

    let isManuallyScrolling = false; // Flag to indicate manual scrolling

    // Function to handle smooth scroll up
    const scrollUp = () => {
      if (!isManuallyScrolling) { // Check if not manually scrolling
        const scrollStep = window.scrollY / 5; // Adjust the number to control the scroll speed
        if (window.scrollY > 0) {
          window.scrollTo(0, window.scrollY - scrollStep);
          window.requestAnimationFrame(scrollUp);
        }
      }
    };

    // Event listener for manual scrolling detection
    window.addEventListener('wheel', () => {
      isManuallyScrolling = true; // Set flag to true when manual scrolling is detected

      // Reset flag after a short delay (e.g., 200ms)
      setTimeout(() => {
        isManuallyScrolling = false;
      }, 200); // Adjust the delay as needed
    });






    return (
        <div className='second-bar' onClick={scrollUp}>
            <p className='second-bar-txt'>{overskrift}</p>
        </div>
    )
}

export default Overskrift