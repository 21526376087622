import React, {useState, useEffect, useContext} from 'react';
import Daily from './trendingdaily.json'
import Monthly from './trending_monthly.json'
import Weekly from './trending_weekly.json'
import {MediaContext} from "../MediaContext";



const Leftbox = () => {
    const {addDataSource, dataSource} = useContext(MediaContext);
    const { addOverskrift } = useContext(MediaContext);
    const [selectedItemId, setSelectedItemId] = useState(null); // State to track the selected item
    let weekly

    /*const trends = () => {
        fetch(`https://fidia.no/data_eh/trending_weekly.json`, {cache: "no-store"})
            .then((response) => {
                if (!response.ok) {
                    console.log(`Error fetching trends.`)
                }
                weekly = response.json();
            })
    }
    trends()
    console.log(weekly)*/

    const trendFeed = (source, name) => {
        addDataSource(source)
        addOverskrift(name)
        setSelectedItemId(source)


        // weird idk
        //console.log('datasource: ', dataSource)
        //console.log('Selected Item ID: ', selectedItemId)
        //console.log('name: ', name)
    }
    return (
        <div className='trendingBox' >
            <div className='headline'>Trender</div>
            <div className='contentbox'>
                <div className='tabs'>
                    <input type='radio' className='tabs__radio' name='tabs-example' id='Daily' defaultChecked />
                    <label htmlFor='Daily' className='tabs__label'>
                        Dag
                    </label>
                    <div className='tabs__content'>
                        <ul className='content-list content-list-daily'>
                            {Object.values(Daily).map((value, subValue) => (
                                    <li
                                        id={`d${subValue+1}`}
                                        onClick={() => trendFeed(`d${subValue+1}`, Object.keys(Daily)[subValue])}
                                        className={selectedItemId === `d${subValue+1}` ? 'selected-item' : 'trend'}>
                                        {Object.keys(Daily)[subValue]}
                                    </li>

                            ))}
                        </ul>
                    </div>

                    <input type='radio' className='tabs__radio' name='tabs-example' id='Weekly' />
                    <label htmlFor='Weekly' className='tabs__label'>
                        Uke
                    </label>
                    <div className='tabs__content'>
                        <ul className='content-list content-list-weekly'>
                            {Object.values(Weekly).map((value, subValue) => (
                                    <li
                                        id={`w${subValue+1}`}
                                        onClick={() => trendFeed(`w${subValue+1}`, Object.keys(Weekly)[subValue])}
                                        className={selectedItemId === `w${subValue+1}` ? 'selected-item' : ''}>
                                        {Object.keys(Weekly)[subValue]}
                                    </li>
                            ))}
                        </ul>
                    </div>

                    <input type='radio' className='tabs__radio' name='tabs-example' id='Monthly' />
                    <label htmlFor='Monthly' className='tabs__label'>
                        Måned
                    </label>
                    <div className='tabs__content'>
                        <ul className='content-list content-list-monthly'>
                            {Object.values(Monthly).map((value, subValue) => (
                                    <li
                                        id={`m${subValue+1}`}
                                        onClick={() => trendFeed(`m${subValue+1}`, Object.keys(Monthly)[subValue])}
                                        className={selectedItemId === `m${subValue+1}` ? 'selected-item' : ''}>
                                        {Object.keys(Monthly)[subValue]}
                                    </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Leftbox