import React from "react";
import Header from "./components/header";
import Overskrift from "./components/overskriftkategori";
import Sidebar from "./components/sidebar"

const Konto = () => {
    return (
        <div>
            <Header />
            <Overskrift />
            <Sidebar />
        </div>


    )
}

export default Konto