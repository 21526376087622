import React, {useContext, useEffect} from "react";
import ProfileComponent from "./profileGroup";
import Livealgo from "./livealgo";
import AddProfileComponent from "./addprofile";

import {MediaContext} from "../MediaContext";

const RightBox = () => {

    const { profiles, setProfiles, uncheckedProfiles, daOptions, setDaOptions, loggedIn } = useContext(MediaContext)
    useEffect(() => {
        const fetchData = async () => {
        let kilder;
        let order = [];
            //console.log(loggedIn)
            kilder = await import('./nyhetskilder.json');


            if (window.localStorage.getItem('loggedIn') === 'true' && window.localStorage.getItem('metadata')) {
                const metadata = window.localStorage.getItem('metadata');
                    if (metadata) {
                        const mediadata = window.localStorage.getItem('metadata').split('/')[0].split(',')
                        mediadata.map((entry, index) => {
                            order.push(Object.keys(kilder)[entry])
                            console.log(kilder[entry])
                        })
                        setProfiles(order)
                        const resten = Object.keys(kilder.default).filter(key => {
                          // Check if the current key is not present in the 'order' array
                          //console.log('key: ', key)
                          //console.log('kilder: ', kilder[key])
                          //console.log(order.includes(key))
                          return !order.includes(key)

                        });
                        //console.log('resten: ', resten)
                        setDaOptions(resten)

                    }
            } else {
                try {
                    // Set the posts state with the imported data
                    setProfiles(Object.keys(kilder.default).slice(0, 8)); // Use .default for ES modules
                    setDaOptions(Object.keys(kilder.default).slice(8))
                } catch (error) {
                    console.error('Error fetching profiles:', error);
                    setProfiles([]); // Set default value or handle the error as needed
                }
            }


        };
            fetchData();
        }, []); // Trigger the effect when dataSource changes

    return (
        <div className='rightbox'>
            <div className='rightbox-headline'>Medier</div>
            <div className='profile-group' >
                {profiles.map((entry, index) => {
                    return <ProfileComponent id={`${entry.lower}-profile`} medie={entry} alt={`Profile Picture ${index}`} dataUserId={index} />}
                )}

                <AddProfileComponent />
            </div>
            <Livealgo />
        </div>
    )
}

export default RightBox