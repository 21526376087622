// Import necessary React components and styling
import { React, useEffect } from 'react';
import './App.css'; // Import your CSS styles
import AppRouter from "./AppRouter";
import {MediaProvider} from "./MediaContext";



// Main App component
const App = () => {


    return (
        <MediaProvider>

            <AppRouter />
        </MediaProvider>
    );
};

export default App;
