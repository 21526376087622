// Import necessary React components and styling
import { React, useEffect } from 'react';
import './App.css'; // Import your CSS styles
import Header from './components/header.jsx'
import Feed from './components/feed.jsx'
import Leftbox from "./components/leftbox";
import Rightbox from "./components/rightbox";
import DragRightBox from "./components/draggablarightbox";
import Overskrift from "./components/overskriftkategori";
import { MediaProvider } from './MediaContext.js';



// Main App component
const Home = () => {
    useEffect(() => {
        document.title='Fidia'


        // Create a new link element
        const link = document.createElement('link');

        // Set attributes for the link element
        link.rel = 'icon';
        link.type = 'image/png'; // or 'image/x-icon' for .ico format
        link.href = 'http://ifeedia.com/wp-content/uploads/2023/12/logo.png'; // Specify the path to your dynamic favicon

        // Append the link element to the document head
        document.head.appendChild(link);

        // Optionally, you can remove the previously added favicon link if needed
        // return () => { document.head.removeChild(link); };
    }, []);

    return (
        <div className="app">
            <Header />
            <Leftbox />
            <Rightbox />
            <Overskrift />
            <Feed />
        </div>
    );
};

export default Home;
