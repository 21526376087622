import React, { createContext, useState } from 'react';


export const MediaContext = createContext();

export const MediaProvider = ({ children }) => {
  const [loggedIn, setLoggedIn] = useState(false)


  const [uncheckedMedia, setUncheckedMedia] = useState([]);

  const addUncheckedMedia = (media) => {
    setUncheckedMedia([...uncheckedMedia, media]);
  };

  const removeUncheckedMedia = (media) => {
    setUncheckedMedia(uncheckedMedia.filter((item) => item !== media));
  };




  const [LaFilteredTags, setLaFilteredTags] = useState([]);

  const addLaFilteredTags = (tags) => {
    setLaFilteredTags([...LaFilteredTags, tags]);
  };

  const removeLaFilteredTags = (tags) => {
    setLaFilteredTags(LaFilteredTags.filter((item) => item !== tags));
  };

  const createLaFilteredTags = (tags) => {
    const uniqueTags = new Set([...LaFilteredTags, ...tags]);

    setLaFilteredTags(Array.from(uniqueTags)); // Spread existing tags and new tags
  };

  const clearLaFilteredTags = () => {
    setLaFilteredTags([])
  }

  const [dataSource, setDataSource] = useState('nyheter'); // Introduce a state for the data source
  const addDataSource = (source) => {
    setDataSource('')
    setDataSource(source);
  };


  const [overskrift, setOverskrift] = useState('Forside'); // Introduce a state for the data source
  const addOverskrift = (skrift) => {
    setOverskrift('')
    setOverskrift(skrift);
  };


  const [initials, setInitials] = useState('HO')

  const addInitials = (inits) => {
    setInitials(inits);
  };

  const [currentIndex, setCurrentIndex] = useState(0);



  const [profiles, setProfiles] = useState([])

  const defaultProfiles = async () => {
    let kilder;
    kilder = await import('./components/nyhetskilder.json');
    setProfiles(Object.keys(kilder.default).slice(0, 8)); // Use .default for ES modules
    setDaOptions(Object.keys(kilder.default).slice(8))
  }

  const [daOptions, setDaOptions] = useState([])

  const [metadata, setMetadata] = useState('')

  const [mediaOrder, setMediaOrder] = useState([])









  return (
    <MediaContext.Provider value={{ uncheckedMedia, addUncheckedMedia, removeUncheckedMedia, currentIndex, setCurrentIndex, LaFilteredTags, addLaFilteredTags, removeLaFilteredTags, createLaFilteredTags, clearLaFilteredTags, dataSource, addDataSource, overskrift, addOverskrift, initials, addInitials, profiles, setProfiles, defaultProfiles, loggedIn, setLoggedIn, daOptions, setDaOptions, mediaOrder, setMediaOrder }}>
      {children}
    </MediaContext.Provider>
  );
};
