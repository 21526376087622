// AppRouter.js
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './Login';
import FireLogin from './FireLogin'
import FireSignUp from "./fireSignUp";
import Home from './Home';
import Sport from './Sport'
import Politikk from "./Politikk";
import Business from "./Business";
import Konto from "./Konto";
import Test from'./Test'

const AppRouter = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/logine" element={<Login />} />
        <Route path="/login" element={<FireLogin />} />
        <Route path="/signup" element={<FireSignUp />} />
        <Route path="/sport" element={<Sport />} />
        <Route path="/politikk" element={<Politikk />} />
        <Route path="/business" element={<Business />} />
        <Route path="/konto" element={<Konto />} />
        <Route path="/test" element={<Test />} />

      </Routes>
    </Router>
  );
};

export default AppRouter;
