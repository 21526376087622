// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

import {getAuth} from 'firebase/auth'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyB87BYnYkxkZWXhl_j03_yKuVNbrhHAcmw",
  authDomain: "fidia-f5853.firebaseapp.com",
  projectId: "fidia-f5853",
  storageBucket: "fidia-f5853.appspot.com",
  messagingSenderId: "239029954583",
  appId: "1:239029954583:web:9c46a65a3f0efc9e891452",
  measurementId: "G-LSX33P6ED8"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const analytics = getAnalytics(firebaseApp);

export const auth = getAuth(firebaseApp)

