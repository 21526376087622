import React, {useContext, useEffect, useState} from 'react';
import axios from 'axios';
import kildebilder from './components/nyhetskildebilder.json'
import creds from './components/credentials.json'
import {MediaContext} from "./MediaContext.js";
import {Link} from "react-router-dom";


const FireLogin = () => {
  const [credentials, setCredentials] = useState({ username: '', password: '' });
  const { addInitials, initials, setLoggedIn, loggedIn } = useContext(MediaContext);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setCredentials({ ...credentials, [name]: value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (creds.hasOwnProperty(credentials['username'])) {}

      if (creds[credentials['username']][0] === credentials['password']) {
        console.log('logged in')
        console.log('metadata: ', creds[credentials['username']][1])
        window.localStorage.clear()
        setLoggedIn(true)
        window.localStorage.setItem('loggedIn', 'true')
        window.localStorage.setItem('metadata', creds[credentials['username']][1])

        window.localStorage.setItem('user', credentials['username'])


        // console.log(loggedIn)


        window.location.href = '/'
      }
      else {
        console.log('wrong credentials')
      }
      // Redirect to dashboard or protected route
    } catch (error) {
      console.error('Login failed', error);
    }
  };

  return (
    <div className='tihihi'>
      <form onSubmit={handleSubmit}>
        <img src={kildebilder['logo']} style={{ display: 'block', marginRight: 'auto', marginLeft: 'auto', width: '50%', borderRadius: '50%', alignContent: 'center', padding: '20px' }}/>

        <input
          type="text"
          name="username"
          placeholder="Username"
          onChange={handleChange}
          autoFocus='true'
          className="tihihi-input" // Added the class name
        />

        <p>FIREEEE</p>
        <input
          type="password"
          name="password"
          placeholder="Password"
          onChange={handleChange}
          className="tihihi-input" // Added the class name
        />
        <button type="submit" className="tihihi-button">Login</button> {/* Added the class name */}
        <div className="form-link">
            <span>Dont have an account? <a><Link to='/signup'> Sign up</Link></a></span>
        </div>
      </form>
    </div>
  );
};

export default FireLogin;
